import { toast } from "react-toastify";

export const dateHelper = (date: Date, opt: Intl.DateTimeFormatOptions) => {
  /**
   * @params
   *  Todo # Provide
   *  1. a date instance
   *  2. Options
   *
   * @returns {string}
   */
  return date.toLocaleDateString("default", opt);
};

export const generateId = () => {
  /**
   * @return {string}
   * used as an id
   * e.g 4yg020wn
   * * Of length 10
   */
  const len = 10;
  return Math.random()
    .toString(36)
    .substring(2, len + 2);
};

export const displaySuccess = (message: string) => {
  toast(message, {
    position: "top-center",
    bodyClassName: "!text-blue-800",
    className: "!bg-blue-200",
    autoClose: 1500,
  });
};

export const displayError = (message: string) => {
  toast(message, {
    position: "top-center",
    bodyClassName: "!text-red-800",
    className: "!bg-red-200",
    autoClose: 3000,
  });
};

export const displayWarning = (message: string) => {
  toast(message, {
    position: "top-center",
    bodyClassName: "!text-orange-800",
    className: "!bg-orange-200",
    autoClose: 3000,
  });
};

export const displayInfo = (message: string) => {
  toast(message, {
    position: "top-center",
    bodyClassName: "!text-gray-800",
    className: "!bg-gray-200",
    autoClose: 3000,
  });
};

export function removeEmptyFields(obj: Record<string, any>) {
  const _obj: typeof obj = {};

  for (let x in obj) {
    if (obj[x]) {
      _obj[x] = obj[x];
    }
  }

  return _obj;
}
