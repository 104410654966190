import * as yup from "yup";

const phoneNumber = yup.string().notRequired();

const ForgotPassSchema = yup
  .object({
    email: yup
      .string()
      .email("Provide a valid email")
      .required("Provide your email"),
  })
  .required();

const ResetSchema = yup
  .object({
    password: yup.string().required("Provide your password"),
    confirm: yup.string().required("Confirm your password"),
  })
  .required();

const signInSchema = yup
  .object({
    email: yup
      .string()
      .email("Provide a valid email")
      .required("Provide your email"),
    password: yup.string().required("Provide a password"),
  })
  .required();

const signUpSchema = ({
  requireCity = false,
  requireState = false,
}: {
  requireState: boolean;
  requireCity: boolean;
}) =>
  yup
    .object({
      name: yup.string().required("Provide the company name"),
      phoneNumber: yup.string().notRequired(),
      city: requireCity
        ? yup.string().required("Provide the city")
        : yup.string(),
      state: requireState
        ? yup.string().required("Provide the state")
        : yup.string(),
      // country: yup.string().required("Provide the country"),
      email: yup
        .string()
        .email("Provide a valid email")
        .required("Provide your email"),
      password: yup.string().required("Provide a password"),
      confirmPassword: yup.string().required("Provide a password"),
    })
    .required();

const userSignUpSchema = ({
  requireCity = false,
  requireState = false,
}: {
  requireState?: boolean;
  requireCity?: boolean;
}) =>
  yup
    .object({
      firstName: yup.string().required("Provide the first name"),
      lastName: yup.string().required("Provide the last name"),
      phoneNumber,
      city: requireCity
        ? yup.string().required("Provide the city")
        : yup.string(),
      state: requireState
        ? yup.string().required("Provide the state")
        : yup.string(),
      // country: yup.string().required("Provide the country"),
      password: yup.string().required("Provide a password"),
      email: yup.string(),
    })
    .required();

const DeveloperPassSchema = yup
  .object({
    password: yup
      .string()
      .min(6, "Password must not be less than 6 characters")
      .required("Provide your password"),
    confirm: yup
      .string()
      .oneOf([yup.ref("password")], "Password must match")
      .required("Confirm your password"),
  })
  .required();

const DeveloperInfoSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber,
    // country: yup.string().required("Provide the state"),
    // state: yup.string().required("Provide the state"),
    // city: yup.string().notRequired(),
    companyName: yup.string().notRequired(),
  })
  .required();

export {
  signInSchema,
  signUpSchema,
  ResetSchema,
  userSignUpSchema,
  ForgotPassSchema,
  DeveloperPassSchema,
  DeveloperInfoSchema,
};

///.matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)
