import React, { FormEvent, useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputField from "../../components/InputField";
import useFetch from "../../hooks/useFetch";
import { UsersSignupApi, getAllCountries } from "../../API/auth";
import SelectField from "../../components/SelectField";
import StatesAndCities from "../../constants/data";
import { yupResolver } from "@hookform/resolvers/yup";
import { userSignUpSchema } from "../../validation/auth.validation";
import { useForm } from "react-hook-form";
import AuthLayout from "../../Layout/Auth";
import PasswordInput from "../../components/PasswordInput";
import { errorStyle, flexer } from "../../styles/globalStyles";
import { getCities, getStates } from "../../API/countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Country } from "../../react-app-env";
import { Option } from "../../components/SelectField/SelectField";
import { removeEmptyFields } from "../../Utils";

interface Creds {
  country: string;
  state: string;
  city: string;
  type: string;
  email: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  phoneNumber: string;
}

const initialCreds = {
  email: "",
  city: "",
  state: "",
  country: "",
  password: "",
  lastName: "",
  firstName: "",
  phoneNumber: "",
  confirmPassword: "",
  type: "projectOwner",
};

export default function Signup() {
  const { isLoading, load } = useFetch();
  const [error, setError] = useState<string>();
  //
  const navigation = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const fromRef = useMemo(() => {
    return searchParams.get("fromreferralPage") === "true";
  }, [searchParams]);
  const {
    load: loadCities,
    successResponse: cities,
    usageCount: CityAPICount,
    isLoading: areCitiesLoading,
  } = useFetch<string[]>({
    initialData: [],
  });
  const {
    successResponse: StatesRes,
    isLoading: areStateLoading,
    usageCount: StateAPICount,
    load: loadStates,
  } = useFetch<{
    states: { name: string; state_code: string }[];
  }>({
    initialData: { states: [] },
  });
  const {
    load: loadCountries,
    successResponse: allCountries,
    isLoading: areCountriesLoading,
  } = useFetch<{ [key: number]: Country }>({
    storeWholeResponse: true,
  });

  useEffect(() => {
    loadCountries(getAllCountries());
  }, []);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Creds>({
    reValidateMode: "onChange",
    defaultValues: initialCreds,
    resolver: yupResolver(
      userSignUpSchema({
        // requireState: StateAPICount > 0 && !StatesRes ? false : true,
        // requireCity: (cities && cities?.length < 1) || !cities ? false : true,
      })
    ),
  });
  //(cities && cities?.length < 1) || !cities ? false : true,
  const country = watch("country");
  const state = watch("state");
  const city = watch("city");
  let values = watch();
  const cca2 = useMemo(() => {
    if (!allCountries || !country) return "ng";

    const hasAmatch = Object.values(allCountries).find(
      (one) =>
        country ===
        (one.name?.common ||
          one.name?.official ||
          one.name?.nativeName["0"].common ||
          one.name?.nativeName["0"].official)
    );

    if (hasAmatch) return hasAmatch.cca2.toLowerCase();
    return "ng";
  }, [country]);

  useEffect(() => {
    if (country) {
      setValue("city", "");
      setValue("state", "");
      loadStates(getStates(country));
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      setValue("city", "");
      loadCities(getCities({ country, state }));
    }
  }, [state]);

  const handleChange = (key: keyof Creds, value: string) => {
    setError("");
    setValue(key, value, {
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSubmit((creds) => {
      const payload: any = removeEmptyFields({
        ...creds,
      });
      // if (!payload.city && payload.country === "United Kingdom")
      //   delete payload.city;
      // check if the passwords match
      if (creds.password !== creds.confirmPassword)
        return setError("Passwords should match");
      // delete unwanted field
      delete payload.confirmPassword;
      //
      load(UsersSignupApi(payload))
        .then(() => navigation(`/verification?email=${creds.email}`))
        .catch((er) => setError(er.message));
    })();
  };

  const countries = useMemo(() => {
    if (!allCountries) return [];

    const temp = Object.values(allCountries)
      .map((country) => ({
        value:
          country.name?.common ||
          country.name?.official ||
          country.name?.nativeName["0"].common ||
          country.name?.nativeName["0"].official,
        icon: country.flag,
      }))
      .sort((a, b) => (a.value || "").localeCompare(b.value));

    let data: Option[] = [];

    for (const country of temp) {
      if (!country.value) continue;
      if (country.value.toLocaleLowerCase() === "nigeria") {
        // puts nigeria at the first position
        data = [country, ...data];
      } else {
        data.push(country);
      }
    }

    return data;
  }, [allCountries]);

  const muted = useMemo(() => {
    return Object.keys(errors)[0];
  }, [errors, values]);

  const Form = (
    <>
      {
        <h1 className="text-center font-Medium">
          {fromRef ? "Sign Up" : "Project Owner"}
        </h1>
      }
      <div className="flex items-start justify-between w-full">
        <InputField
          label="First name"
          placeholder="First name"
          error={errors.firstName?.message}
          register={register("firstName")}
        />
        <div className="spacer" />
        <InputField
          label="Last name"
          placeholder="Last name"
          error={errors.lastName?.message}
          register={register("lastName")}
        />
      </div>
      <InputField
        label="Email address"
        register={register("email")}
        placeholder="e.g example@internet.com"
        error={errors.email?.message}
        type="email"
      />
      {/* <div className={flexer}>
        <SelectField
          showSearch
          value={country}
          label="Country"
          className="!w-[184px]"
          error={errors.country?.message}
          isLoading={areCountriesLoading}
          placeholder="Select your country"
          onChange={(val) => handleChange("country", val)}
          data={countries}
        />
        <div className="spacer" />
        <SelectField
          showSearch
          value={state}
          disabled={!country}
          className="!w-[184px]"
          label="State/Province"
          isLoading={areStateLoading}
          error={errors.state?.message}
          placeholder="Select your state / province"
          onChange={(val) => handleChange("state", val)}
          data={StatesRes?.states
            .map((one) => ({ value: one.name }))
            .sort((a, b) => a.value.localeCompare(b.value))}
        />
      </div> */}
      {/* <div className={flexer}>
        <SelectField
          showSearch
          label="City"
          value={city}
          disabled={!state}
          className="!w-[184px]"
          isLoading={areCitiesLoading}
          error={errors.city?.message}
          placeholder="Select your city"
          onChange={(val) => handleChange("city", val)}
          data={cities
            ?.map((one) => ({ value: one }))
            .sort((a, b) => a.value.localeCompare(b.value))}
        />
        <div className="spacer" />
        <div className="!w-[184px] flex flex-col items-start mb-2">
          <label className="capitalize text-bash text-sm mb-1">
            Phone number
          </label>
          <PhoneInput
            country={cca2}
            containerClass="w-full"
            value={values.phoneNumber}
            inputClass="!w-full !flex-1 !py-6 !border-bash"
            onChange={(phone) => {
              handleChange("phoneNumber", phone);
            }}
          />
          <p className={errorStyle}>{errors.phoneNumber?.message}</p>
        </div>
      </div> */}
      <PasswordInput
        label="Password"
        placeholder="••••••••••••"
        register={register("password")}
        error={errors.password?.message}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="••••••••••••"
        error={errors.password?.message}
        register={register("confirmPassword")}
      />
      {error ? (
        <div>
          <div className="halfSpacer" />
          <p className="error">{error}</p>
        </div>
      ) : (
        <div className="spacer" />
      )}
      <Button
        text="Sign Up"
        isLoading={isLoading}
        type={muted ? "muted" : "primary"}
      />
    </>
  );

  return (
    <AuthLayout
      {...{ Form, submitHandler }}
      footer={{
        labelQuestion: "Already have an account",
        labelActionDesc: "Sign in",
        link: "/signin",
      }}
    />
  );
}
